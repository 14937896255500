<template>
  <div v-if="memberProfile">
    <div class="row row-cols-1 g-4">
      <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage">
        <template v-slot:before><span class="loadingText">請稍後...</span> </template></loading
      >
      <div class="col">
        <div class="card shadow p-3 bg-body rounded g-light bg-gradient">
          <!-- <div class="upper">
            <img src="https://i.imgur.com/Qtrsrk5.jpg" class="img-fluid" />
          </div>

          <div class="user text-center">
            <div class="profile">
              <img src="https://i.imgur.com/JgYD2nQ.jpg" class="rounded-circle" width="80" />
            </div>
          </div> -->

          <div class="mt-3 text-center">
            <h1 class="mb-2">
              <small class="fs-4"><i class="bi bi-question-circle" data-bs-toggle="modal" data-bs-target="#memberhelpModal" title="會員資格說明"></i></small> {{ memberProfile.members_role_take.role_type | getRoleTypeText(memberProfile.members_role_take.role_lv) }}
              <sapn v-if="memberProfile.members_take.is_email_confirm"><img src="@/assets/email-validation-32.png" width="32" alt="email-validation-32" title="已通過電子郵件驗證" /></sapn>
              <sapn v-if="memberProfile.members_role_take.role_type === 'Sponsored'"><img src="@/assets/premium-100.png" width="32" alt="premium-100" title="贊助會員" /></sapn>
            </h1>
            <span class="text-muted d-block mb-2" v-if="memberProfile.members_role_take.role_type === 'Free'">會員效期 {{ memberProfile.members_role_take.role_expiry_dt | formatDate }}</span>
            <span class="text-muted d-block mb-2" v-else-if="memberProfile.members_role_take.role_type === 'Sponsored'">-</span>
            <span class="text-muted d-block mb-2" v-else>會員效期 {{ memberProfile.members_role_take.role_expiry_dt | formatDate }}</span>

            <span class="badge text-bg-danger fs-6" v-if="!memberProfile.members_take.is_email_confirm">未完成驗證</span>
            <span class="badge text-bg-success fs-6" v-else>已完成驗證</span>
            <hr />
            <span v-if="!memberProfile.members_take.is_email_confirm"><button type="button" class="btn btn-warning" @click="sendUserVaildCode">帳號驗證</button></span>

            <!-- <button class="btn btn-primary btn-sm follow">{{ memberProfile.members_take.is_email_confirm }}</button> -->

            <div class="d-flex justify-content-between align-items-center mt-4 px-4">
              <div class="stats me-3">
                <h6 class="mb-0">可新增的頻道總數</h6>
                <span>{{ memberProfile.notify_user_take.notify_channel_count }}</span>
              </div>

              <div class="stats ms-3">
                <h6 class="mb-0">可新增的規則總數</h6>
                <span>{{ memberProfile.notify_user_take.notify_rule_count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="memberhelpModal" tabindex="-1" aria-labelledby="memberhelpModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="memberhelpModalLabel">會員資格說明</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <table class="table table-striped text-center align-middle">
              <tbody>
                <tr>
                  <td colspan="2" rowspan="2">票價追蹤管理</td>
                  <td colspan="3">註冊會員</td>
                </tr>
                <tr>
                  <td>一般會員</td>
                  <td>驗證會員</td>
                  <td>贊助會員</td>
                </tr>
                <tr>
                  <td rowspan="2">通知</td>
                  <td>E-mail通知</td>
                  <td>✅</td>
                  <td>✅</td>
                  <td>✅</td>
                </tr>
                <tr>
                  <td>LINE Notify通知</td>
                  <td>✅</td>
                  <td>✅</td>
                  <td>✅</td>
                </tr>
                <tr>
                  <td rowspan="2">使用額度</td>
                  <td>票價規則</td>
                  <td>3組</td>
                  <td>5組</td>
                  <td>20組</td>
                </tr>
                <tr>
                  <td>LINE Notify頻道</td>
                  <td>1組</td>
                  <td>3組</td>
                  <td>10組</td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
            <div><span>*2024/9/30 前適用</span></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment"
  import axios from "axios"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/vue-loading.css"

  export default {
    props: ["memberProfile"],
    mounted() {
      this.memberProfile
    },
    components: {
      // LineLogin: () => import("./LineLogin.vue"),
      //   BootstrapTable: BootstrapTable,
      // LineLogin,
      Loading,
    },
    data: function () {
      return {
        isLoading: false,
        fullPage: true,
      }
    },
    methods: {
      onCancel() {},
      sendUserVaildCode() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 5000)
        const me = this

        const json_d = {
          uuid: this.memberProfile.uuid,
          from: "portal",
        }
        axios({
          method: "POST",
          url: `${me.$apiUrl2}sendUserAccountVaildCode`,
          data: json_d,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
        }).then(function (response) {
          const res = response.data
          if (res.status === 201) {
            if (res.statusText === "Created" || res.statusText === "Update") {
              me.$alert("驗證碼已寄出，還煩請檢查所登錄的 E-mail 信箱", "系統訊息", "success").then(() => {
                // window.location.href = `/Login`
              })
            }
          }

          if (res.status === 201) {
            if (res.statusText === "Select") {
              me.$alert("已經完成驗證，無須再重複驗證", "系統訊息", "success").then(() => {
                // window.location.href = `/Login`
              })
            }
          }

          if (res.status === 202) {
            if (res.statusText === "Select") {
              me.$alert("如仍未收到驗證信，請於一小時候再重新申請補寄", "系統訊息", "success").then(() => {
                // window.location.href = `/Login`
              })
            }
          }
        })
      },
    },
    filters: {
      getWeekDay: function (date) {
        const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
        return weeks[moment(date).isoWeekday()]
      },
      formatDate: function (date) {
        return moment(date).format("YYYY/MM/DD HH:mm")
      },
      getRoleTypeText: function (role_type, role_lv) {
        let role_name = ""
        switch (role_type) {
          case "Free": {
            if (role_lv === 0) {
              role_name = "一般會員"
            } else if (role_lv === 1) {
              role_name = "驗證會員"
            } else {
              role_name = "會員"
            }
            break
          }
          case "Sponsored":
            role_name = "贊助會員"
            break

          default:
            break
        }
        return role_name
      },
    },
  }
</script>
<style>
  /* @import url("https://fonts.googleapis.com/css2?family=Poppins:weight@100;200;300;400;500;600;700;800&display=swap"); */

  /* body {
    background-color: #545454;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
  } */

  /* .container {
    height: 100vh;
  } */

  .card {
    width: 100%;
    /* border: none; */
    border-radius: 15px;
    padding: 8px;
    background-color: #fff;
    position: relative;
    /* height: 370px; */
  }

  .upper {
    height: 100px;
  }

  .upper img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .user {
    position: relative;
  }

  .profile img {
    height: 80px;
    width: 80px;
    margin-top: 2px;
  }

  .profile {
    position: absolute;
    top: -50px;
    left: 38%;
    height: 90px;
    width: 90px;
    border: 3px solid #fff;

    border-radius: 50%;
  }

  .follow {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px;
  }

  .stats span {
    font-size: 29px;
  }
</style>
